<template>
	<banner-style1 :position="3"></banner-style1>
	<div class="certificate">
		<div class="certificate-table c w">
			<h3>证书查询</h3>
			<div class="table-card">
				<el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-position="left" label-width="200px"
					class="ruleForm" :size="formSize" hide-required-asterisk status-icon>
					<el-form-item label="赛季 | COMPETITION">
						<!-- <div class="cascader">2021 - 2022 赛季</div> -->
						<select class="cascader" name="" id="">
							<option>2021 ~ 2022赛季</option>
						</select>
					</el-form-item>
					<el-form-item label="证书 | CERTIFICATE">
						<el-radio-group v-model="ruleForm.resource1">
							<el-radio label="获奖作品" />
							<el-radio label="优秀指导老师" />
							<el-radio label="赛季评委" />
						</el-radio-group>
					</el-form-item>
					<el-form-item label="级别 | LEVEL">
						<el-radio-group v-model="ruleForm.resource2">
							<el-radio label="国赛" />
							<el-radio label="省赛" />
						</el-radio-group>
					</el-form-item>
					<el-form-item label="类型 | TYPE" >
						<el-radio-group v-model="ruleForm.resource3">
							<el-radio label="命题" />
							<el-radio label="非命题" />
						</el-radio-group>
					</el-form-item>
					<el-form-item label="组别 | GROUP">
						<el-radio-group v-model="ruleForm.resource4">
							<el-radio label="学生组" />
							<el-radio label="教师组" />
							<el-radio label="设计师组" />
						</el-radio-group>
					</el-form-item>
					<el-form-item label="单位 | COMPANY" >
						<el-input v-model="ruleForm.name" placeholder="请输入单位名称" />
					</el-form-item>
					<el-form-item label="指导 | GUIDANCE">
						<el-input v-model="ruleForm.name2" placeholder="请输入指导老师姓名" />
					</el-form-item>
					<el-form-item label="作者 | AUTHOR">
						<el-input v-model="ruleForm.name3" placeholder="请输入作者姓名" />
					</el-form-item>
					<el-form-item label="内容 | CONTENT">
						<el-input v-model="ruleForm.name4" placeholder="请输入证书编号或作品名称或作品IP" />
					</el-form-item>
				</el-form>
			</div>
			<div class="btn">
				<button>查询</button>
			</div>
		</div>
	</div>
</template>

<script setup>
	import {
		reactive,
		ref
	} from 'vue'


	const formSize = ref('default')
	const ruleFormRef = ref()
	const ruleForm = reactive({
		name: '',
		name2: '',
		name3: '',
		name4: '',
		region: '',
		count: '',
		date1: '',
		date2: '',
		delivery: false,
		type: [],
		resource1: '',
		resource2: '',
		resource3: '',
		resource4: '',
		desc: '',
	})

	const rules = reactive({
		name: [{
				required: true,
				message: 'Please input Activity name',
				trigger: 'blur'
			},
			{
				min: 3,
				max: 5,
				message: 'Length should be 3 to 5',
				trigger: 'blur'
			},
		],
		region: [{
			required: true,
			message: 'Please select Activity zone',
			trigger: 'change',
		}, ],
		count: [{
			required: true,
			message: 'Please select Activity count',
			trigger: 'change',
		}, ],
		date1: [{
			type: 'date',
			required: true,
			message: 'Please pick a date',
			trigger: 'change',
		}, ],
		date2: [{
			type: 'date',
			required: true,
			message: 'Please pick a time',
			trigger: 'change',
		}, ],
		type: [{
			type: 'array',
			required: true,
			message: 'Please select at least one activity type',
			trigger: 'change',
		}, ],
		resource: [{
			required: true,
			message: 'Please select activity resource',
			trigger: 'change',
		}, ],
		desc: [{
			required: true,
			message: 'Please input activity form',
			trigger: 'blur'
		}, ],
	})
	const submitForm = async (formEl) => {
		if (!formEl) return
		await formEl.validate((valid, fields) => {
			if (valid) {
				console.log('submit!')
			} else {
				console.log('error submit!', fields)
			}
		})
	}
	const options = Array.from({
		length: 10
	}).map((_, idx) => ({
		value: `${idx + 1}`,
		label: `${idx + 1}`,
	}))
</script>

<style lang="scss" scoped>
	.certificate {
		margin-bottom: 180px;

		.certificate-table {
			h3 {
				text-align: center;
				margin: 30px 0;
				font-size: 30px;
			}

			.table-card {
				border: 2px solid $themeColor;
				width: 1200px;
				border-radius: 10px;
				padding: 10px;

				.ruleForm {
					background: linear-gradient(to bottom, #f0f4f2, #c2e3db);

					.el-form-item {
						border-bottom: 2px solid #fff;

					}

					.el-form-item--default {
						margin: 0;
					}

					::v-deep .el-form-item__label {
						border-right: 2px solid #fff;
						padding: 15px 15px 15px 30px;
					}

					::v-deep .el-form-item__content {
						padding: 0 20px;
					}
				}
			}
		}
	}

	.cascader {
		position: relative;
		background-color: #e1e1e1;
		width: 100%;
		cursor: pointer;
		border-radius: 10px;
		padding: 0 10px;
		box-shadow: 10px 10px 20px #c9caca inset;
		height: 32px;
		outline: none;

		&::after {
			content: '';
			display: inline-block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 600px;
			height: 1px;
			// background-color: #000;
			box-shadow: 2px 2px 2px #c8cbca;
		}
	}

	.btn {
		text-align: center;
		margin: 30px;

		button {
			color: #fff;
			padding: 8px 20px;
			background-color: #ba511e;
			border: none;
			cursor: pointer;
			width: 120px;
			border-radius: 4px;
		}
	}

	::v-deep .el-cascader {
		width: 100%;
	}

	::v-deep .el-cascader-menu__list {
		width: 935px !important;
	}
</style>
